<template>
  <div
    class="overflow-hidden relative"
    :class="container ? 'mb-6 md:mb-16' : null"
  >
    <div :class="{ small: small, container }">
      <!-- Fixes error when quickly navigating backwards twice -->
      <NuxtErrorBoundary>
        <Swiper
          :modules="modules"
          :navigation="{
            nextEl: sliderNext,
            prevEl: sliderPrev,
          }"
          :slidesPerView="'auto'"
          :spaceBetween="spaceBetween"
          :initialSlide="0"
          :grabCursor="false"
          :watchOverflow="false"
          :watchSlidesProgress="true"
          :observer="true"
          :observeParents="true"
          :loop="loop"
          :grid="{
            rows: mobileRows,
            fill: mobileRows > 1 ? 'row' : 'column',
          }"
          :mousewheel="{
            forceToAxis: true,
          }"
          :autoplay="
            isMm
              ? false
              : {
                  delay: 6000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }
          "
          :speed="500"
          :freeMode="true"
          :breakpoints="{
            640: {
              grid: {
                rows: 1,
              },
              spaceBetween: spaceBetween / 2,
            },
          }"
          :a11y="{
            prevSlideMessage: $t('Prev'),
            nextSlideMessage: $t('Next'),
          }"
          class="!overflow-visible"
          @slideChange="emit('slideChange', $event)"
        >
          <slot />
        </Swiper>
      </NuxtErrorBoundary>
    </div>
    <div
      v-if="fade"
      class="absolute right-0 top-0 h-full w-10 bg-gradient-to-l from-white z-10 hidden md:block"
      :class="!container ? 'pl-10' : ''"
    />
    <div
      v-if="fade"
      class="absolute left-0 top-0 h-full w-10 bg-gradient-to-r from-white z-10 hidden md:block"
      :class="!container ? 'pl-10' : ''"
    />
    <div
      ref="sliderNext"
      slot="button-next"
      class="swiper-button-next select-none"
    >
      <IconForward />
    </div>
    <div
      ref="sliderPrev"
      slot="button-prev"
      class="swiper-button-prev select-none"
    >
      <IconBackward />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper } from 'swiper/vue'
import type ExtendedSwiper from '~/typesManual/swiper'
import {
  FreeMode,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  Grid,
  A11y,
} from 'swiper'

// Import Swiper styles
import 'swiper/css/bundle'

import IconForward from '~/assets/icons/forward.svg?component'
import IconBackward from '~/assets/icons/backward.svg?component'
const { isMm } = useBrand()

const emit = defineEmits<{
  slideChange: [swiper: ExtendedSwiper]
}>()

const modules = [
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  FreeMode,
  Grid,
  A11y,
]
const sliderNext = ref(null)
const sliderPrev = ref(null)

defineProps({
  container: {
    type: Boolean,
    default: true,
  },
  spaceBetween: {
    type: Number,
    default: 30,
  },
  fade: {
    type: Boolean,
    default: false,
  },
  mobileRows: {
    type: Number,
    default: 1,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss" scoped>
:deep() {
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    width: 260px !important;
    height: auto;

    .small & {
      width: auto !important;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    @apply bg-white w-12 h-12 rounded-full border-gray-300 border shadow-lg transition-colors duration-100;
    &:after {
      display: none;
    }
    display: none;
    @screen md {
      display: flex;
    }
    &:hover {
      @apply bg-gray-300;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }
}
</style>
